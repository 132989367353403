import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import './css/index.scss';

import { createStore, Store } from 'redux';
import { Provider } from 'react-redux';
import storeService from './services/storeService';
import dispatherService from './services/dispatherService';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './store/reducers';
import integrationService from './services/integrationService';
import actions from './store/actions/types';
import pjson from '../package.json';

const store: Store = createStore(reducers, {}, composeWithDevTools());
storeService.setStore(store);

const root = document.getElementById('webbot-widget');
dispatherService.setParamsByElement(root);

(async () => {
    try {
        let loadConfigRequest = await integrationService.loadConfig();
        const config = loadConfigRequest.data;

        storeService.getStore().dispatch({
            type: actions.general.FETCH_CONFIG_SUCCESS,
            payload: config,
        });

        let loadResultRequest = await integrationService.getResult();
        let result = loadResultRequest.data.extensions;

        storeService.getStore().dispatch({
            type: actions.general.FETCH_RESULT_SUCCESS,
            payload: result,
        });
    } catch (e) {
        console.log({ e });
        storeService.getStore().dispatch({
            type: actions.general.FETCH_CONFIG_ERROR,
            payload: e,
        });
        storeService.getStore().dispatch({
            type: actions.general.FETCH_RESULT_ERROR,
            payload: e,
        });
    }
})();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    root
);

console.log(
    `%c Web-bot widget version: ${pjson.version} `,
    'background-color: #ffdd2d; color: #333;'
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
